import React from "react";
import "./AdventureActivity.css";

// Import images directly
import adventureImage from "../../assets/images/slider/new/activity/13.png";
import trekkingImage from "../../assets/images/slider/new/activity/14.png";
import campfireImage from "../../assets/images/slider/new/activity/15.png";
import offroadImage from  "../../assets/images/slider/new/activity/16.png";
import campingImage from  "../../assets/images/slider/new/activity/17.png";
import exploringImage from  "../../assets/images/slider/new/activity/18.png";

// Define activities with imported images
const activities = [
  { title: "Adventure", destinations: 15, image: adventureImage },
  { title: "Trekking", destinations: 12, image: trekkingImage },
  { title: "Camp Fire", destinations: 7, image: campfireImage },
  { title: "Off Road", destinations: 15, image: offroadImage },
  { title: "Camping", destinations: 13, image: campingImage },
  { title: "Exploring", destinations: 25, image: exploringImage }
];

const ActivityItems = () => {
  return (
    <div className="activity-section">
      <div className="activity-header">
        <h3>Travel by Activity</h3>
        <h2>ADVENTURE & ACTIVITY</h2>
        <p>
        Embark on an exhilarating journey with our adventure and activity-focused tours, designed to cater to thrill-seekers and exploration enthusiasts alike. From scaling majestic peaks and navigating rugged trails to diving into crystal-clear waters and embracing cultural escapades, our curated experiences promise to ignite your sense of adventure. Each activity is meticulously crafted to offer not only an adrenaline rush but also a deep connection to the natural and cultural wonders of your destination. Whether you’re conquering challenging landscapes or engaging with local traditions, our tours ensure unforgettable moments and stories that will stay with you long after your journey ends.
        </p>
      </div>
      <div className="activity-items">
        {activities.map((activity, index) => (
          <div key={index} className="activity-item">
            <img src={activity.image} alt={activity.title} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityItems;
