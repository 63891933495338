// src/pages/admin/Admin.js
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './css/Admin.css'; 
import LogoutButton from './LogoutButton';

const Admin = () => {
    return (
        <div className="admin-container">
            <div className="sidebar">
                <h2>Admin Panel</h2>
                <ul>
                    <li><Link to="dashboard">Dashboard</Link></li>
                    <li><Link to="manage-packages">Manage Packages</Link></li>
                    <li><Link to="settings">Settings</Link></li>
                </ul>
                <LogoutButton /> 
            </div>
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
};

export default Admin;
