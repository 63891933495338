import React from "react";
import { Carousel } from "react-bootstrap";
import sliderImg from "../../assets/images/slider/new/HomeImge/1.png";
import sliderImg1 from "../../assets/images/slider/new/HomeImge/2.png";
import sliderImg2 from "../../assets/images/slider/new/HomeImge/3.png";
import sliderImg3 from "../../assets/images/slider/new/HomeImge/4.png";
import sliderImg4 from "../../assets/images/slider/new/HomeImge/5.png";
import "../Banner/banner.css"

const Banner = () => {
  return (
    <>
      <section className="slider">
        <Carousel variant="dark">
          <Carousel.Item>
            <img src={sliderImg2} className="d-block w-100" alt="First slide" />
            <Carousel.Caption>
              <div className="slider_des">
                <h5 className="heading">
                Travelling around<span>the world</span>
                </h5>
                <p className="sub_text">
                Welcome to TourGlobalHub, your ultimate guide to discovering the globe’s most breathtaking destinations! Our platform is dedicated to helping you find and book the most unforgettable travel experiences.
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img src={sliderImg1} className="d-block w-100" alt="First slide" />
            <Carousel.Caption>
              <div className="slider_des">
                <h5 className="heading">
                Discover Your  <span>Next Adventure</span>
                </h5>
                <p className="sub_text">
                Traveling Around the World is your gateway to incredible destinations and unforgettable experiences. Explore our expertly curated travel packages and start planning your dream trip today. 
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={sliderImg} className="d-block w-100" alt="First slide" />
            <Carousel.Caption>
              <div className="slider_des">
                <h5 className="heading">
                Discover Your  <span>Next Adventure</span>
                </h5>
                <p className="sub_text">
                Traveling Around the World is your gateway to incredible destinations and unforgettable experiences. Explore our expertly curated travel packages and start planning your dream trip today. 
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={sliderImg3} className="d-block w-100" alt="First slide" />
            <Carousel.Caption>
              <div className="slider_des">
                <h5 className="heading">
                Discover Your  <span>Next Adventure</span>
                </h5>
                <p className="sub_text">
                Traveling Around the World is your gateway to incredible destinations and unforgettable experiences. Explore our expertly curated travel packages and start planning your dream trip today. 
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={sliderImg4} className="d-block w-100" alt="First slide" />
            <Carousel.Caption>
              <div className="slider_des">
                <h5 className="heading">
                Discover Your  <span>Next Adventure</span>
                </h5>
                <p className="sub_text">
                Traveling Around the World is your gateway to incredible destinations and unforgettable experiences. Explore our expertly curated travel packages and start planning your dream trip today. 
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
};

export default Banner;
