import React from "react";
import "./PhotoAlbums.css";

import Img1 from '../../assets/images/slider/bg_2.jpg';
import Img2 from '../../assets/images/slider/bg_5.jpg';
import Img3 from '../../assets/images/slider/services-1.jpg';
import Img4 from '../../assets/images/slider/bgImage.png';

const PhotoAlbums = () => {
  return (
    <div className="photo-album">
      <div className="gallery-header">
        <h4>OUR TOUR GALLERY</h4>
        <h2>BEST TRAVELER'S PHOTOS</h2>
        <p className="text-photos">
        Explore the stunning moments captured by our travelers on their adventures with us. Each photo tells a unique story of the beauty and excitement found in our destinations.

Immerse yourself in the vibrant colors of distant landscapes, the charm of local cultures, and the joy of unforgettable experiences. From breathtaking views to candid moments, our gallery showcases the highlights of our tours.
        </p>
      </div>

      <div className="gallery-grid">
        <img src={Img1} alt="Traveler 1" className="gallery-img large-img" />
        <img src={Img2} alt="Traveler 2" className="gallery-img" />
        <img src={Img3} alt="Traveler 3" className="gallery-img" />
        <img src={Img4} alt="Traveler 4" className="gallery-img large-img" />
      </div>
    </div>
  );
};

export default PhotoAlbums;
