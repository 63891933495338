// src/pages/admin/ManagePackagesPage.jsx

import React, { useState, useEffect } from 'react';
import { DataTable, Column, Button } from 'primereact';
import 'primereact/resources/themes/saga-blue/theme.css'; // Theme
import 'primereact/resources/primereact.min.css';         // Core styles
import 'primeicons/primeicons.css';                       // Icons
import AddPackagePage from './AddPackagePage';
// import './css/ManagePackagesPage.css'; 

const ManagePackagesPage = () => {
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);

    useEffect(() => {
        fetchPackages();
    }, []);

    const fetchPackages = async () => {
        // Mock data
        setPackages([{
            id: 1,
            title: '3 Days Sri Lanka Package',
            duration: '3 days',
            description: 'Explore the beauty of Sri Lanka over 3 days.',
        }]);
    };

    const showDialog = (pkg) => {
        setSelectedPackage(pkg);
        setDisplayDialog(true);
    };

    const hideDialog = () => {
        setDisplayDialog(false);
        setSelectedPackage(null);
    };

    const savePackage = (pkg) => {
        if (pkg.id) {
            // Update logic
            const updatedPackages = packages.map(p => p.id === pkg.id ? pkg : p);
            setPackages(updatedPackages);
        } else {
            // Add logic
            const newPackage = { ...pkg, id: packages.length + 1 };
            setPackages([...packages, newPackage]);
        }
        hideDialog();
    };

    const deletePackage = (pkg) => {
        const filteredPackages = packages.filter(p => p.id !== pkg.id);
        setPackages(filteredPackages);
    };

    return (
        <div className="page-container">
            <div className="page-header">
                <h1>Manage Packages</h1>
            </div>
            <Button className="add-button" label="Add New Package" icon="pi pi-plus" onClick={() => showDialog({})} />
            <DataTable value={packages} paginator rows={10} header="Packages" className="package-table">
                <Column field="title" header="Title" />
                <Column field="duration" header="Duration" />
                <Column field="description" header="Description" />
                <Column body={(rowData) => (
                    <>
                        <Button label="Edit" icon="pi pi-pencil" onClick={() => showDialog(rowData)} />
                        <Button label="Delete" icon="pi pi-trash" onClick={() => deletePackage(rowData)} />
                    </>
                )} />
            </DataTable>

            <AddPackagePage
                visible={displayDialog}
                onHide={hideDialog}
                packageData={selectedPackage}
                onSave={savePackage}
            />
        </div>
    );
};

export default ManagePackagesPage;
