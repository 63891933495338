// src/pages/admin/DashboardPage.js
import React from 'react';

const DashboardPage = () => {
    return (
        <div>
            <h1>Dashboard</h1>
            {/* Add dashboard content here */}
        </div>
    );
};

export default DashboardPage;
