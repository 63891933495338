import React from "react";
import "../Features/features.css";

import feature1 from "../../assets/images/slider/new/svg/airplane.png";
import feature2 from "../../assets/images/slider/new/svg/best-deal.png";
import feature3 from "../../assets/images/slider/new/svg/booking.png";
import feature4 from "../../assets/images/slider/new/svg/reliable.png";
import { Card, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Features = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay:false,
    autoplaySpeed:1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          prevArrow:false,
          nextArrow:false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:false,
          nextArrow:false,
        },
      },
    ],
  };

  const featureList = [
    {
      id: 1,
      image: feature1,
      title: "Global Destinations Await",
      des: "Explore stunning destinations worldwide with our curated travel packages. From tropical beaches to vibrant cities, discover your next adventure.",
    },
    {
      id: 2,
      image: feature2,
      title: "Exclusive Offers & Savings",
      des: "Enjoy special deals and discounts on a wide range of travel experiences. Save more on your dream vacations with our exclusive offers.",
    },
    {
      id: 3,
      image: feature3,
      title: "Easy Booking & Flexibility",
      des: "Book your travels with ease. Benefit from flexible booking options, last-minute deals, and free cancellations to suit your travel plans.",
    },
    {
      id: 4,
      image: feature4,
      title: "Trusted Travel Partners",
      des: "Travel with confidence knowing that we work with trusted partners to ensure top-notch service and support throughout your journey.",
    },
  ];
  

  return (
    <>
    
      <section className="feature-section">
        <Container>
          <Row>
            <Col md="12">
              <Slider {...settings}>
                {featureList.map((feature, inx) => {
                  return (
                    <Card key={inx}>
                      <Card.Img
                        variant="top"
                        src={feature.image}
                        className="img-fluid"
                        alt={feature.title}
                      />
                      <Card.Title>{feature.title}</Card.Title>
                      <Card.Text>{feature.des}</Card.Text>
                    </Card>
                  );
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Features;
