import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './AboutUs.css'; 
import Imge from '../../assets/images/slider/new/aboutimg/tghlogoImg.png';

const AboutusHome = () => {
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    return (
        <section className="about-section">
            <Container>
                <Row className="align-items-center">
                    {/* Content Column */}
                    <Col lg={6} md={12} sm={12} className="about-content-column order-lg-2 order-md-1">
                        <div className="inner-content" data-aos="fade-up">
                            <div className="about-sec-title">
                                <h2>Welcome to Tour Global Hub</h2>
                            </div>
                            <div className="about-text">
                                Welcome to Tour Global Hub, your gateway to extraordinary travel experiences around the world! We specialize in delivering unforgettable journeys tailored to your unique interests and preferences, ensuring every trip is a remarkable adventure. Our commitment to exceptional service and personalized itineraries makes us your ideal travel partner for exploring the globe.
                            </div>
                        </div>
                    </Col>

                    {/* Image Column */}
                    <Col lg={6} md={12} sm={12} className="about-image-column order-lg-1 order-md-2">
                        <div className="about-inner-image" data-aos="fade-right">
                            <figure className="about-image">
                                <a href="#" className="lightbox-image" data-fancybox="images">
                                    <img src={Imge} alt="About Tour Global Hub" />
                                </a>
                            </figure>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default AboutusHome;
