import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './css/AddPackagePage.css'; // Import the CSS file

const AddPackagePage = ({ visible, onHide, packageData, onSave }) => {
    const [pkg, setPkg] = useState({
        basicDetails: {
            title: '',
            duration: '',
            description: '',
            photos: []
        },
        itinerarySide: [], // Initialize as an empty array
    });
    const [newDay, setNewDay] = useState({
        day: '',
        location: '',
        description: '',
        activities: [],
        accommodation: [],
        mealPlan: [],
        transferMode: [],
        travelTime: '',
        photos: []
    });

    useEffect(() => {
        if (packageData) {
            setPkg(packageData);
        }
    }, [packageData]);

    const handleSave = () => {
        onSave(pkg);
    };

    const handleAddDay = () => {
        setPkg({
            ...pkg,
            itinerarySide: [...(pkg.itinerarySide || []), newDay]
        });
        setNewDay({
            day: '',
            location: '',
            description: '',
            activities: [],
            accommodation: [],
            mealPlan: [],
            transferMode: [],
            travelTime: '',
            photos: []
        });
    };

    const handleFileUpload = (e, field, index = null) => {
        const files = e.files.map(file => file.name);
        if (index !== null) {
            const updatedDays = [...pkg.itinerarySide];
            updatedDays[index][field] = files;
            setPkg({
                ...pkg,
                itinerarySide: updatedDays
            });
        } else {
            setPkg({
                ...pkg,
                [field]: files
            });
        }
    };

    return (
        <Dialog
            header={pkg?.id ? 'Edit Package' : 'Add Package'}
            visible={visible}
            style={{ width: '90vw' }}
            onHide={onHide}
            footer={
                <div className="dialog-footer">
                    <Button label="Save" icon="pi pi-check" onClick={handleSave} />
                    <Button label="Cancel" icon="pi pi-times" onClick={onHide} />
                </div>
            }
        >
            <div className="p-fluid">
                <h3>Basic Details</h3>
                <div className="p-field">
                    <label htmlFor="title">Title</label>
                    <InputText
                        id="title"
                        value={pkg?.basicDetails?.title || ''}
                        onChange={(e) => setPkg({ ...pkg, basicDetails: { ...pkg.basicDetails, title: e.target.value } })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="duration">Duration</label>
                    <InputText
                        id="duration"
                        value={pkg?.basicDetails?.duration || ''}
                        onChange={(e) => setPkg({ ...pkg, basicDetails: { ...pkg.basicDetails, duration: e.target.value } })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="description">Description</label>
                    <InputText
                        id="description"
                        value={pkg?.basicDetails?.description || ''}
                        onChange={(e) => setPkg({ ...pkg, basicDetails: { ...pkg.basicDetails, description: e.target.value } })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="photos">Photos</label>
                    <FileUpload
                        name="photos"
                        customUpload
                        uploadHandler={(e) => handleFileUpload(e, 'basicDetails.photos')}
                        multiple
                    />
                </div>

                <h3>Itinerary</h3>
                <div className="p-field">
                    <label htmlFor="day">Day</label>
                    <InputText
                        id="day"
                        value={newDay.day}
                        onChange={(e) => setNewDay({ ...newDay, day: e.target.value })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="location">Location</label>
                    <InputText
                        id="location"
                        value={newDay.location}
                        onChange={(e) => setNewDay({ ...newDay, location: e.target.value })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="description">Description</label>
                    <InputText
                        id="description"
                        value={newDay.description}
                        onChange={(e) => setNewDay({ ...newDay, description: e.target.value })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="activities">Activities</label>
                    <InputText
                        id="activities"
                        value={newDay.activities.join(', ')}
                        onChange={(e) => setNewDay({ ...newDay, activities: e.target.value.split(', ') })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="accommodation">Accommodation</label>
                    <InputText
                        id="accommodation"
                        value={newDay.accommodation.join(', ')}
                        onChange={(e) => setNewDay({ ...newDay, accommodation: e.target.value.split(', ') })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="mealPlan">Meal Plan</label>
                    <InputText
                        id="mealPlan"
                        value={newDay.mealPlan.join(', ')}
                        onChange={(e) => setNewDay({ ...newDay, mealPlan: e.target.value.split(', ') })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="transferMode">Transfer Mode</label>
                    <InputText
                        id="transferMode"
                        value={newDay.transferMode.join(', ')}
                        onChange={(e) => setNewDay({ ...newDay, transferMode: e.target.value.split(', ') })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="travelTime">Travel Time</label>
                    <InputText
                        id="travelTime"
                        value={newDay.travelTime}
                        onChange={(e) => setNewDay({ ...newDay, travelTime: e.target.value })}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="photos">Photos</label>
                    <FileUpload
                        name="photos"
                        customUpload
                        uploadHandler={(e) => handleFileUpload(e, 'itinerarySide.photos', pkg.itinerarySide.length)}
                        multiple
                    />
                </div>
                <Button label="Add Day" icon="pi pi-plus" onClick={handleAddDay} className="p-button-success" />

                {pkg.itinerarySide && (
                    <DataTable value={pkg.itinerarySide} paginator rows={5} className="data-table">
                        <Column field="day" header="Day" />
                        <Column field="location" header="Location" />
                        <Column field="description" header="Description" />
                        <Column field="activities" header="Activities" />
                        <Column field="accommodation" header="Accommodation" />
                        <Column field="mealPlan" header="Meal Plan" />
                        <Column field="transferMode" header="Transfer Mode" />
                        <Column field="travelTime" header="Travel Time" />
                        <Column field="photos" header="Photos" />
                    </DataTable>
                )}

                {/* Repeat similar sections for inclusions, exclusions, booking information, cancellation policy, package details, other details, and map details */}
            </div>
        </Dialog>
    );
};

export default AddPackagePage;
