import { Route, Routes,useLocation  } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import Admin from "./pages/admin/Admin";
import LoginPage from "./pages/admin/LoginPage";
import AboutUs from "./components/about/Aboutus";
import ContactUs from "./components/contact/ContactUs";

//admin
import DashboardPage from "./pages/admin/DashboardPage";
import ManagePackagesPage from "./pages/admin/ManagePackagesPage";
import SettingsPage from "./pages/admin/admin_component/SettingsPage";




function App() {
  const location = useLocation();
  const isAdminPage = location.pathname.startsWith('/admin-panel');

  return (
    <>
      {!isAdminPage && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/admin-panel" element={<LoginPage />} />
        <Route path="/admin-panel/dashboard" element={<Admin />}>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="manage-packages" element={<ManagePackagesPage />} />
          <Route path="settings" element={<SettingsPage/>} />
        </Route>
      </Routes>
      {!isAdminPage && <Footer />}
    </>
  );
}

export default App;
