import React from "react";
import aboutUsImage from "../../assets/images/slider/new/aboutimg/7.png"; // Center image
import companyIntroImage from "../../assets/images/slider/new/aboutimg/8.png"; // Right side image
import "../Banner/banner.css"; // Make sure this CSS file is correctly applied

const AboutUs = () => {
  return (
    <>
      <section className="about-us">
        <div className="about-us-image-container">
          <img src={aboutUsImage} alt="About Us" className="about-us-image" />
          <div className="about-us-text">
            <h1>About Us</h1>
            <p>We are committed to providing exceptional travel experiences with a focus on sustainability and social responsibility.</p>
          </div>
        </div>
        <div className="company-intro-container">
          <img src={companyIntroImage} alt="Company Introduction" className="company-intro-image" />
          <div className="company-intro-text">
            <h2>Company Introduction</h2>
            <p>Our company was founded with the mission to offer unforgettable travel experiences while making a positive impact on the communities we visit.</p>
          </div>
        </div>
        <div className="company-vision">
          <h2>Our Vision</h2>
          <p>We envision a world where travel enriches lives and fosters a deeper understanding of diverse cultures, all while contributing to the well-being of the planet.</p>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
