import React from 'react';
import './ContactUs.css'; // Import the CSS file

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="contact-us-content">
        <h1>Contact Us</h1>
        <p>
          We would love to hear from you! If you have any questions or need assistance, feel free to reach out to us through the following contact information:
        </p>
        <div className="contact-info">
          <div className="contact-item">
            <h2>Address</h2>
            <p>123 Travel Street, Wanderlust City, TR 45678</p>
          </div>
          <div className="contact-item">
            <h2>Phone</h2>
            <p>(123) 456-7890</p>
          </div>
          <div className="contact-item">
            <h2>Email</h2>
            <p><a href="mailto:info@tourglobalhub.com">info@tourglobalhub.com</a></p>
          </div>
          <div className="contact-item">
            <h2>Social Media</h2>
            <p>
              <a href="https://facebook.com/tourglobalhub" target="_blank" rel="noopener noreferrer">Facebook</a><br />
              <a href="https://twitter.com/tourglobalhub" target="_blank" rel="noopener noreferrer">Twitter</a><br />
              <a href="https://instagram.com/tourglobalhub" target="_blank" rel="noopener noreferrer">Instagram</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
