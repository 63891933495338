// src/components/admin/LogoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/LoginPage.css'; 


const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Perform logout logic here (e.g., clear tokens, etc.)
        // Redirect to login page
        navigate('/admin-panel');
    };

    return (
        <button onClick={handleLogout} className="logout-button">
            Logout
        </button>
    );
};

export default LogoutButton;
