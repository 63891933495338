import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './Testimonial.css';

const Testimonial = () => {
 


  return (
  <div>
  <h1>Hello Testomonial</h1>
  </div>
  );
};

export default Testimonial;
